<template>
  <v-container fluid class="d-flex flex-wrap justify-center align-center">
        <tinybox v-if="images.length > 0" @close="tinyboxClose" v-model="tinyboxIndex" loop :images="images"/>

      <v-row align="center"  class="hidden-md-and-up" >
         <v-col cols="12">   
        <v-window v-model="window1" :vertical="vertical" :autorun="autorun">
          <v-window-item v-for="(path,index) in gallerys[0]" :key="index">
              <card :title="''" :shadow="false" :id="path.id" :file="{}" :category="''" :url="path.path" @open="openGallery($event)" :height="238"/>
          </v-window-item>
        </v-window>
      </v-col>
      </v-row> 
    <v-row align="center" justify="center" class="hidden-sm-and-down">
      
      <v-col v-if="gallerys && gallerys[0]" cols="3" class="ma-1 pa-1" >

        <v-window v-model="window1"  :vertical="false" :autorun="autorun">
          <v-window-item v-for="(path,i) in gallerys[0]" :key="i">
              <card :title="''" :shadow="false" :id="path.id" :file="{}" :category="''" :url="path.path" @open="openGallery($event)" :height="238"/>
          </v-window-item>
        </v-window>
</v-col>
        <v-col v-if="gallerys && gallerys[1]" cols="3" class="ma-1 pa-1" >

        <v-window v-model="window2"  :vertical="true" :autorun="autorun">
          <v-window-item v-for="(path,i) in gallerys[1]" :key="i">
              <card :title="''" :shadow="false" :id="path.id" :file="{}" :category="''" :url="path.path" @open="openGallery($event)" :height="238"/>
          </v-window-item>
        </v-window>
</v-col>
        <v-col v-if="gallerys && gallerys[2]" cols="3" class="ma-1 pa-1" >

          <v-window v-model="window3"  :vertical="false" :autorun="autorun">
          <v-window-item v-for="(path,i) in gallerys[2]" :key="i">
              <card :title="''" :shadow="false" :id="path.id" :file="{}" :category="''" :url="path.path" @open="openGallery($event)" :height="238"/>
          </v-window-item>
        </v-window>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import galleryService from "../../../service/galleryService";

export default {
  name: "GaleryHome",
  methods: {
    openGallery(id){
       galleryService.get({id:id}).then(res => {
         this.images = res.data.map(img => {
           return {
             src:img.path,
             thumbnail:'',
             caption:'',
             alt:''
           }
         })
        
       })
    },
    getGallery() {
      galleryService.get({all:true,limit:3}).then(res => {
        
        const data = res.data
        this.gallerys = data.map((gallery,index) => {
          if(gallery && gallery.paths.length > 0){
            return String(gallery.paths).split(',').map(path => {
              return {
                id:gallery.id,
                path:path
              }
            })
          }
        });

    //       if(this.gallerys && this.gallerys.length > 0){
    //   setInterval(() => {
    //     if (!this.autorun) return;
    //     if (this.gallerys && this.gallerys.length > 0 && ++this.window1 >= this.gallerys[0].length) this.window1 = 0;
    //     if (this.gallerys && this.gallerys.length > 1 && ++this.window2 >= this.gallerys[1].length) this.window2 = 0;
    //     if (this.gallerys && this.gallerys.length > 2 && ++this.window3 >= this.gallerys[2].length) this.window3 = 0;
    //   }, 4000);
    // }

      });
    },tinyboxClose(){
    this.images = []
    this.tinyboxIndex = 0
  },
  },
  mounted() {

    this.getGallery();

  
  },
  components: {
    Card: () => import("../../../components/commons/Card.vue"),
    Tinybox:() => import("vue-tinybox"),

  },
  data() {
    return {
      gallerys: [],
      gallery: [],
      window1: 0,
      window2: 0,
      window3: 0,
      images:[],
      autorun: true,
      vertical: true,
      tinyboxIndex:0
    };
  }
};
</script>

<style lang="sass">
.card-image
  background-size: cover
  background-repeat: no-repeat
</style>
